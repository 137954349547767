/*
* ERP specific plugins to insert new worksteps into a production-route
* BEFORE or AFTER the current position (there must be two different handlers).
* These plugins are only a workaround until something smarter can be achieved with dynamic views, controllers, and routes
* used in `ERP_ProdOrder_routing.html` template
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

var render = require('./awuxView.jq.js').render;
var assignEvents = require('./events.js').assignEvents;

/* Insert new worksteps BEFORE the current position */
;(function ($) {
  $.fn.erpRoutingWorkstepsBefore = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'this': $(this)
      }, options);

      /* assign initial action to link */
      $(this).on('click', function (event) {
        onClick(event);
      });

      var onClick = function (event) {

        /* first, we need to turn off the default event handler on click */
        event.preventDefault();

        /* Find the outer table of the link that was clicked */
        console.log('clicked "add head before" button');
        /* collect route-key and add to data for rendering of inlet template */
        var table = event.currentTarget.closest('table');
        /* the closest $('[data-awux-model="ERP_WorkStep"]') element represents the previous work-step;
         * but sometimes, a route is empty, so no previous work-steps exist - in this case
         * we do not provide a work-step
         */
        var workStepEl = event.currentTarget.closest('[data-awux-model="ERP_WorkStep"]');

        var prevWorkStepEl = null;
        /* different from erp-worksteps-after handler, we need one work-step before the closes work-step */
        if (typeof workStepEl !== 'undefined' && workStepEl !== null) {
          prevWorkStepEl = $(workStepEl).prev('[data-awux-model="ERP_WorkStep"]');
        }
        var workStepJson = null;
        var position = '0';
        /* if no previous work-step is provided to the API, we must set position to 0 explicitly, or it will be
         * defaulting to -1 which means, the new work-steps will be appended to the existing work-steps of the route
         */
        if (prevWorkStepEl.length > 0) {
          workStepJson = $(prevWorkStepEl).data('awux-jsons');
          position = null;
        }
        render('/erp/planning/ui/inlets/insert_worksteps.html', {
          'prodPlant': $(table).parent().prevAll('[data-awux-model="ERP_ProdPlant"]').data('awux-jsons'),
          'prodOrder': $(table).parent().prevAll('[data-awux-model="ERP_ProdOrder"]').data('awux-jsons'),
          'prodRoute': $(table).prev('[data-awux-model="ERP_ProdRoute"]').data('awux-jsons'),
          'workStep': workStepJson,
          'position': position
        }, function (output) {
          /* insert output into DOM before assigning events */
          var $tr = $(event.currentTarget.closest('tr'));
          if ($tr.prevAll('tr[data-awux-repeat="head"]').length === 0) {
            var prev = $tr.prev();
            $(prev).after(output);
            assignEvents(output);
            /* inject serialized JSON payload into head element, will be used in all new line elements to render into view template */
            $tr.prevAll('tr[data-awux-repeat="head"]').data('awux-payload', {
              'prodPlant': $(table).parent().prevAll('[data-awux-model="ERP_ProdPlant"]').data('awux-jsons'),
              'prodOrder': $(table).parent().prevAll('[data-awux-model="ERP_ProdOrder"]').data('awux-jsons'),
              'prodRoute': $(table).prev('[data-awux-model="ERP_ProdRoute"]').data('awux-jsons'),
              'workStep': workStepJson,
              'position': position
            });
          }
        });
      };

    });
  };
})(jQuery);


/* Insert new worksteps AFTER the current position */
;(function ($) {
  $.fn.erpRoutingWorkstepsAfter = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'this': $(this)
      }, options);

      /* assign initial action to link */
      $(this).on('click', function (event) {
        onClick(event);
      });

      var onClick = function (event) {
        /* first, we need to turn off the default event handler on click */
        event.preventDefault();

        /* Delete the corresponding line element and its content */
        console.log('clicked "add head after" button');
        /* collect route-key and add to data for rendering of inlet template */
        var table = event.currentTarget.closest('table');
        /* the closest $('[data-awux-model="ERP_WorkStep"]') element represents the previous work-step;
         * but sometimes, a route is empty, so no previous work-steps exist - in this case
         * we do not provide a work-step
         */
        var workStepEl = event.currentTarget.closest('[data-awux-model="ERP_WorkStep"]');
        var workStepJson = null;
        if (typeof workStepEl !== 'undefined' && workStepEl !== null) {
          workStepJson = $(workStepEl).data('awux-jsons');
        }

        render('/erp/planning/ui/inlets/insert_worksteps.html', {
          'prodPlant': $(table).parent().prevAll('[data-awux-model="ERP_ProdPlant"]').data('awux-jsons'),
          'prodOrder': $(table).parent().prevAll('[data-awux-model="ERP_ProdOrder"]').data('awux-jsons'),
          'prodRoute': $(table).prev('[data-awux-model="ERP_ProdRoute"]').data('awux-jsons'),
          'workStep': workStepJson
        }, function (output) {
          /* insert output into DOM before assigning events */
          var $tr = $(event.currentTarget.closest('tr'));
          if ($tr.nextAll('tr[data-awux-repeat="head"]').length === 0) {
            var next = $tr.next();
            $(next).before(output);
            assignEvents(output);
            /* inject serialized JSON payload into head element, will be used in all new line elements to render into view template */
            $tr.nextAll('tr[data-awux-repeat="head"]').data('awux-payload', {
              'prodPlant': $(table).parent().prevAll('[data-awux-model="ERP_ProdPlant"]').data('awux-jsons'),
              'prodOrder': $(table).parent().prevAll('[data-awux-model="ERP_ProdOrder"]').data('awux-jsons'),
              'prodRoute': $(table).prev('[data-awux-model="ERP_ProdRoute"]').data('awux-jsons'),
              'workStep': workStepJson
            });
          }
        });
      };
    });
  };
})(jQuery);