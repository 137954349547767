/*
* AWUX Checkbox plugin extends default <input type="checkbox"> elements 
* by buttons optimized for touch.
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

;(function ($) {
  $.fn.awuxCheck = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'uncheckedLevel': 'btn-default',
        'checkedLevel': 'btn-primary',
        'uncheckedIcon': 'fa-square-o',
        'checkedIcon': 'fa-check-square',
        'label': $(this).closest('label'),
        'group': $(this).closest('.btn-group'),
        'this': $(this)
      }, options);

      $(this).change(function (event) {
        /* on change of the state of this checkbox,
         * apply the desired styling
         * */
        if (settings.this.prop('checked')) {
          setChecked();
        }
        else {
          setUnchecked();
        }
      });

      var setChecked = function () {
        settings.label.find('span').removeClass(settings.uncheckedIcon);
        settings.label.find('span').addClass(settings.checkedIcon);
        settings.label.addClass('active');
        settings.label.removeClass(settings.uncheckedLevel);
        settings.label.addClass(settings.checkedLevel);
      };

      var setUnchecked = function () {
        settings.label.find('span').removeClass(settings.checkedIcon);
        settings.label.find('span').addClass(settings.uncheckedIcon);
        settings.label.removeClass('active');
        settings.label.removeClass(settings.checkedLevel);
        settings.label.addClass(settings.uncheckedLevel);
      };

      /* if checkbox is checked, apply the desired styles
       * unchecked checkboxes already have the desired styles
       * */
      if (settings.this.prop('checked')) {
        setChecked();
      }
    });
  };
})(jQuery);


/*
* AWUX Radio plugin extends default <input type="checkbox"> elements 
* by buttons optimized for touch.
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

;(function ($) {
  $.fn.awuxRadio = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'uncheckedLevel': 'btn-default',
        'checkedLevel': 'btn-primary',
        'uncheckedIcon': 'fa-circle-o',
        'checkedIcon': 'fa-check-circle',
        'label': $(this).closest('label'),
        'group': $(this).closest('.btn-group'),
        'this': $(this)
      }, options);

      $(this).change(function (event) {
        /* on change of the state of this checkbox,
         * apply the desired styling
         * */
        if (settings.this.prop('checked')) {
          setChecked();
        }
      });

      var setChecked = function () {
        /* first all radios in groups to unchecked */
        settings.group.find('label').each(function (index, element) {
          $(element).removeClass('active');
          $(element).removeClass(settings.checkedLevel);
          $(element).addClass(settings.uncheckedLevel);
        });
        settings.group.find('span').each(function (index, element) {
          $(element).removeClass(settings.checkedIcon);
          $(element).addClass(settings.uncheckedIcon);
        });
        /* now remove unchecked classes from this radio and add checked classes */
        settings.label.find('span').removeClass(settings.uncheckedIcon);
        settings.label.find('span').addClass(settings.checkedIcon);
        settings.label.addClass('active');
        settings.label.removeClass(settings.uncheckedLevel);
        settings.label.addClass(settings.checkedLevel);
      };
    });
  };
})(jQuery);


/* the toggleCheckBulk method is used to select or unselect all checkboxes in a bulk-form, e.g. in a search page */
function toggleCheckBulk(elem, bulkForm, bulkName) {
  if ($(elem).prop('checked')) {
    $('input.awux-check-bulk[type="checkbox"][name="' + bulkName + '[][key]"][form="' + bulkForm + '"]').prop('checked', true).change();
  }
  else {
    $('input.awux-check-bulk[type="checkbox"][name="' + bulkName + '[][key]"][form="' + bulkForm + '"]').prop('checked', false).change();
  }
}

// XXX: toggleCheckBulk is used from html onclick attributes
global.toggleCheckBulk = toggleCheckBulk;
