/* global Mustache */

function pluginError(msg) {
  fadingAlert(999, '<<alert_NotImplemented>>: ' + msg, 'danger');
}

module.exports.pluginError = pluginError;

/**
 * parseIntDefault tries to parse the first argument as an int, or uses the second argument if it fails
 * @param  {string} str        the string to parse
 * @param  {int}    defaultval the default value
 * @return {int}               the parsed value or default value
 */
function parseIntDefault(str, defaultval) {
  return !isNaN(+str) ? +str : defaultval;
}

module.exports.parseIntDefault = parseIntDefault;


/**
 * parseBooleanDefault tries to parse the first argument as a boolean, or uses the second argument if it fails
 * @param  {string} str        the string to parse
 * @param  {boolean}    defaultval the default value
 * @return {boolean}               the parsed value or default value
 */
function parseBooleanDefault(str, defaultval) {
  return (typeof str !== 'undefined' && str !== 'false') ? true : (defaultval) ? defaultval : false;
}

module.exports.parseBooleanDefault = parseBooleanDefault;


/* showAlerts() is used by AWUX plugins to present messages in the alert bar */
function showAlerts(alerts) {
  /* `alerts` is either a single object or an array of objects. This is the structure of each Alert object:
   *   id : [0..1] string
   *   	The unique name of the alert class, e.g. `"DCA_ForbiddenError"`. This is used to identify all types of alerts.
   *   title : [1] string
   *   	The translated string describing the alert.
   *   subtitle : [0..1] string
   *		The translated string that indicates the `level` of the alert message as a string, e.g. `"Error"` (in English) or `"Fehler"` (in German), when the `level` is `"error"`.
   *		In AWUX, this value is rendered only for screen-readers in order to improve accessibility.
   *   body : [0..1] html
   *   	The translated HTML-formatted string describing the alert.
   *   code : [0..1] integer
   *   	The HTTP status code of the error, e.g. `409`, `410`.
   *   	For AWUX plugin errors or other UI-only errors the caller can use `999`
   *   level : ['success', 'info', 'warning', 'error']
   *   	The severity level of this error. By default, the app will use the `"error"` level for all 4xx and 5xx codes and `"warning"` level for all 3xx codes. Must be one value of `['success', 'info', 'warning', 'error']`.
   *   	In AWUX, this will affect the color scheme and if the alert will automatically fade out.
   *   item : [0..*] JavaScript object:
   *     An optional list of JavaScript objects that represent items or resources that belong to this alert. Such items are typically entity objects from Datastore.
   *     title : [1] str
   *       The title representing this object.
   *     id : [0..1] int
   *       The visible identifier of this object (if it is an entity).
   *     key : [0..1] str
   *       The URL-safe encoded Datastore key of this object (if it is an entity).
   *       
   * TODO: do we need additional options for all alerts?
   * Additional options for all alerts together:
   *   showBody : bool = false
   *   if true, body is shown immediately; if false, body is only shown if user clicks on alert
   *   fade : bool
   *   if level === 'success' or 'info', alert will fade out automatically else alert will stick; override default behavior with this parameter
   */
  if (typeof alerts === 'undefined' || alerts === null) {
    return;
    /* nothing to do here */
  }
  var allAlerts;
  if ($.isArray(alerts)) {
    /* alerts is an array */
    allAlerts = alerts;
  }
  else {
    /* alerts is not an array, so convert it into an array */
    allAlerts = [alerts];
  }
  /* define the mustache template for alerts (this is built-in here, no need to GET from server */
  var src = '<div class="alert {{#level}}alert-{{.}}{{/level}} col-xs-12 hidden fadingAlert"><a class="close" data-dismiss="alert"><span class="fa fa-times"></span></a>' +
    '<p><a class="alert-link btn" onclick="$(this).parent().parent().find(\'div.awux-alert-body\').toggle()">{{#icon}}<span class="fa {{.}} fa-lg fa-fw"></span>{{/icon}}</a> {{#subtitle}}<span class="sr-only">{{.}}</span>{{/subtitle}}{{#title}}<strong>{{.}}</strong>{{/title}}</p>' +
    '<div class="awux-alert-body {{#collapsed}}hide{{/collapsed}}"><hr>{{{body}}}' +
    '<ul>{{#item}}<li>{{title}}{{#id}}<br><small><span class="text-muted">{{id}}</span></small>{{/id}}</li>{{/item}}</ul><div class="pull-right"><small><small>({{#code}}{{.}}: {{/code}}{{#id}}{{.}}{{/id}})</small></small></div>' +
    '</div>';
  /* iterate over all alerts, and eventually tweak some of the alert members */
  /* alert.fadeOut:  is added to alert object here, if false, the alert message will not fade-out automatically */
  /* alert.collapsed:  is added to alert object here; if false, the alert message is expanded initially */
  for (var i in allAlerts) {
    var alert = allAlerts[i];
    if (typeof alert.code !== 'undefined' && alert.code !== null) {
      alert.code = String(alert.code);
      /* we need a string for the rendering */
    }
    /* make some modifications depending on alert.level */
    if (alert.level === 'success') {
      alert.icon = 'fa-check';
      alert.collapsed = true;
      alert.fadeOut = true;
    }
    else if (alert.level === 'info') {
      alert.icon = 'fa-info-circle';
      alert.collapsed = true;
      alert.fadeOut = true;
    }
    else if (alert.level === 'warning') {
      alert.icon = 'fa-warning';
      alert.collapsed = true;
      alert.fadeOut = false;
    }
    else if (alert.level === 'error') {
      alert.level = 'danger';
      /* change level for corresponding Bootstrap.css class */
      alert.icon = 'fa-flash';
      alert.collapsed = false;
      alert.fadeOut = false;
    }
    var $alert = $(Mustache.render(src, alert));
    $('#alerts').append($alert);

    if (!alert.collapsed) {
      /* hide body when fading in alert*/
      $alert.find('.awux-alert-body').removeClass("hidden");
    }
    $alert.removeClass("hidden").hide().fadeIn(1000);
    if (alert.fadeOut) {
      /* fade out alert message */
      $alert.delay(2000).fadeOut(1500);
    }
  }
}

module.exports.showAlerts = showAlerts;

/* put showAlerts as awuxShowAlerts property of the global window, so it can be
   called as 'awuxShowAlerts' from browser console or Android app's web-view.
   Solution: http://stackoverflow.com/a/9354233/1549523
 */
window.awuxShowAlerts = showAlerts;


/* fadingAlert() is used for alert messages by AWUX plugins: (is going to be replaced by showAlert() */
function fadingAlert(title, body, level) {
  var $alert = $('<div class="alert alert-' + level + ' col-xs-12 hidden fadingAlert">' +
    '<a class="close" data-dismiss="alert">×</a>' +
    '<p><strong>' + title + '</strong> ' + body + '</p>' +
    '</div>');
  $('#alerts').append($alert);
  if (level === 'success') {
    $alert.removeClass("hidden").hide().delay(500).fadeIn(1500).delay(2000).fadeOut(1500);
  }
  else if (level === 'info') {
    $alert.removeClass("hidden").hide().delay(500).fadeIn(1500).delay(2000).fadeOut(1500);
  }
  else if (level === 'warning') {
    $alert.removeClass("hidden").hide().fadeIn(1000);
  }
  else if (level === 'danger') {
    $alert.removeClass("hidden").hide().fadeIn(1000);
  }
}

// XXX: fadingAlert is deprecated (you should use `showAlerts()`), but it is still used by other AWUX plugins and functions, as well as in-page scripts, e.g. `/prodplant/1/my_workplaces` for triggering page reload for all devices of all workers at same workplace
global.fadingAlert = fadingAlert;

