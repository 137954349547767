function awuxHashLoader() {
  /* if URL hash starts with '/', directly GET the modal and show it, by temporarily adding an auto-loader button to DOM so awuxModal plugin's features can be re-used */
  if (typeof window.location.hash !== 'undefined' && window.location.hash.length > 1 && window.location.hash[1] === '/') {
    $('body').append('<a id="awux-modal-autoLoader" class="awux-window" data-awux-url="' + window.location.hash.slice(1, window.location.hash.length) + '"></a>');
    var $awuxModalAutoLoader = $('#awux-modal-autoLoader');
    $awuxModalAutoLoader.awuxWindow().click();
    $awuxModalAutoLoader.remove();
  }
}

module.exports.awuxHashLoader = awuxHashLoader;