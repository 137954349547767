/*global CKEDITOR */
/*
* 
* @depends on
* ~ jQuery    1.11.1
* ~ Bootstrap.js by @fat & @mdo
* ~ jquery.autoselect.js
* ~ jquery.autosearch.js
* @author Maximilian Orth (maxx.orth@gmail.com)
* @author AniX (anh@hatzis.de)
* @version 1.0.4e
*/

var showAlerts = require('./utils.js').showAlerts;
var pluginError = require('./utils.js').pluginError;
var assignEvents = require('./events.js').assignEvents;
var awuxHashLoader = require('./awuxHashLoader.js').awuxHashLoader;

;(function ($) {
  $.fn.awuxWindow = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes
       *
       * overwrite options for individual elements in the JS, e.g.:
       * $("a").awuxWindow({spinnerCSS: "fa fa-refresh fa-spin fa-2x"});
       */
      var settings = $.extend({
        'method': $(this).prop('nodeName').toUpperCase() === 'A' ? 'GET' : typeof $(this).data('awux-method') !== 'undefined' ? $(this).data('awux-method').toUpperCase() : 'GET',
        'url': $(this).data('awux-url'),
        'contentType': 'application/json;charset=utf-8', /* the content-type in which we send the data to server */
        'dataType': 'json', /* the content-type of the response we expect from the server */
        /*
         * An anchor element is always method 'GET', a button element can bei either GET or POST
         * e.g.: <button data-awux-url="/prodplant/Werkstatt/worker/3001/pickup_work?work=123&work=145&work">
         * or <a data-awux-url="/registry/1/accounts/deactivate?user=3001">
         * */
//				'hashID'          : $(this).prop('nodeName').toUpperCase() === 'A' ? $(this).attr('href').split('#')[1] : $(this).data('awux-url').split('#')[1],
        /*
         * #TODO: add support for hash-ID to filter out from response as we did in miniget and minipost plugin
         * Optional. If the url has a hashID, the plugin will only look for the part of the response inside this element.
         * Provide the hash-ID in the data-awux-url value
         * e.g. <button data-awux-url="/prodplant/Werkstatt/set_titles?prodorder=442#ProdOrder-442_set_titles">
         */
        'insertID': typeof $(this).data('awux-insert') === 'undefined' || $(this).data('awux-insert') === '' ? null : $(this).data('awux-insert'),
        /*
         * Required for inlet windows, because we need to know which element in the current page is to be replaced by the window. Not needed for modals.
         * Provide the HTML ID as data-awux-insert value
         * e.g. <button data-awux-insert="#ProdOrder-442_set_titles">
         */
        'mode': typeof $(this).data('awux-insert') === 'undefined' || $(this).data('awux-insert') === '' ? 'modal' : 'inlet',
        /*
         * 'modal' - the window will appear as modal dialog, overlapping the current page
         * 'inlet' - the window will appear as part of the current page (requires data-awux-insert attribute value!)
         */
        'replace': typeof $(this).data('awux-replace') === 'undefined' || $(this).data('awux-replace') === '' ? [] : $(this).data('awux-replace').split(' '),
        /*
         * a list of HTML-IDs of elements to be replaced in the current page if the request is successful; can be empty or only one item
         * e.g. <a data-awux-replace="#workerframe #title #Work-123_status">
         * */
        /* The HTML ID of the window */
        'windowID': typeof $(this).data('awux-id') !== 'undefined' ? $(this).data('awux-id') : 'awuxModal',
        'window': null, /* will be set by button */
        /* formID is the HTML ID of the form to which the button belongs */
        'currentForm': typeof $(this).attr('form') !== 'undefined' ? $('#' + $(this).attr('form')) : $(this).closest('form'),
        /* The HTML ID of the form that stores the selected items of a collection: */
        'bulkForm': $(this).data('awux-bulk-form'),
        /* The name of the collection, used in the name of its input checkboxes, but with-out the nesting for JSON form serialization, e.g. "order", not "order[][key]": */
        'bulkName': $(this).data('awux-bulk-name'),
        'httpParameters': $.parseParams((window.location.href.indexOf('?') !== -1) ? window.location.href.slice(window.location.href.indexOf('?') + 1) : ''),
        'spinnerCSS': 'fa fa-spinner fa-spin fa-3x',
        /* adds the CSS class "fade" to the div.modal, so modals fade in and out; set value to "true" or anything but "false", e.g.: */
        /* <button data-awux-modal-fade="true"> */
        'fade': typeof $(this).data('awux-modal-fade') === 'undefined' || $(this).data('awux-modal-fade') === 'false' ? '' : 'fade',
        /* duration of animation, the lower the slower: */
        'duration': 100, /* duration of animation effects in ms */
        'this': $(this)
      }, options);
      /* The element in the current page which has to be replaced (applies to inlet only) */
      var $insert = $('#' + settings.insertID);

      /* assign initial action to link */
      $(this).on('click', function (event) {
        onClick(event);
      });
      var onClick = function (event) {
        /* first, we need to turn off the default event handler on click */
        event.preventDefault();

        /* Clean-up previous windows */
        if (settings.mode === 'modal') {
          /* this button was clicked and had a window open */
          var $body = $('body');
          if (typeof $body.data('awux-modal') !== 'undefined' && $body.data('awux-modal') !== null) {
            $('body').data('awux-modal').modal('hide');
          }
        }
        // else if (settings.mode === 'inlet') {
        // 		/* TODO: ? */
        // 	}

        startAnimation();

        /* Prepare the initial AJAX request */
        if (typeof settings.httpParameters.m === 'undefined') {
          settings.httpParameters.m = 1;
        }
        var arr = $.map(settings.httpParameters, function (val, key) {
          return {name: key, value: val};
        });
        var _localHttpParameters = $.param(arr);
        var _data;
        if (settings.method === 'POST') {
          cleanEditors();
          console.log('settings.bulkForm = ' + settings.bulkForm);
          console.log('settings.bulkName = ' + settings.bulkName);
          /* For bulk items, let's add hidden inputs for the version IDs of each selected element */
          $('#' + settings.currentForm.prop('id') + ' .awux-temp').remove();
          /* but first remove form elements temporarily appended by the dialog (in earlier request) to the collection form */
          if (typeof settings.bulkForm !== 'undefined' && typeof settings.bulkName !== 'undefined') {
            /* append all form elements of form in response window to the original form that stores the collection: */
            /* TODO: eventually it would be much better, if we do it the other way around:
             * temporarily add the selected keys and their vids from the collection form to the current form that is actually submitted
             * this should work when the collection form is on a page and the current form in a modal (or mini) window, e.g. ERP_ProdOrder_set_deadlines modal with items collected on ERP_ProdOrders_search page
             * and when the collection form and the current form is the same form in the same window, e.g. ERP_Workplace_add_works modal
             *
             * we would need to find out what the current form is, either from the button's form attribute, or finding the button's closest form element
             */
//						var ex1 = $('input[type=checkbox]:not(:checked)');
            /* add hidden key and vid inputs to the current form for each selected item from collection */
            $('input.awux-check-bulk[form="' + settings.bulkForm + '"][type=checkbox]:checked').each(function (index, element) {
              if (settings.currentForm.prop('id') !== settings.bulkForm) {
                /* add hidden key elements only if these are different forms, or we would have duplicates in the current form */
                $('<input type="hidden" name="' + settings.bulkName + '[][key]" value="' + $(element).val() + '" class="awux-temp"/>').appendTo(settings.currentForm);
              }
              $('<input type="hidden" name="' + settings.bulkName + '[][vid]" value="' + $(element).data('vid') + '" class="awux-temp"/>').appendTo(settings.currentForm);
            });
            /* For special cases where only a single item is in the bulk and is not selected by checkbox elements but is hidden in a form;
             * This is typically used for details pages with one item and AWUX window buttons in the toolbar */
            $('input.awux-check-bulk[form="' + settings.bulkForm + '"][type=hidden]').each(function (index, element) {
              if (settings.currentForm.prop('id') !== settings.bulkForm) {
                /* add hidden key elements only if these are different forms, or we would have duplicates in the current form */
                $('<input type="hidden" name="' + settings.bulkName + '[][key]" value="' + $(element).val() + '" class="awux-temp"/>').appendTo(settings.currentForm);
              }
              $('<input type="hidden" name="' + settings.bulkName + '[][vid]" value="' + $(element).data('vid') + '" class="awux-temp"/>').appendTo(settings.currentForm);
            });
          }
          /* get all the data from the form to which the button belongs to as JSON data*/
          _data = JSON.stringify(settings.currentForm.serializeObject());
        }
        else {
          /* get all the data from the form to which the button belongs to as www-url-encoded data*/
          /* var _data = settings.this.closest('form').serialize() + _localHttpParameters + '&m=1'; */
          _data = _localHttpParameters;
        }
//				console.log('_data = ' + _data);

        /* Send the AJAX request and receive response */
        $.ajax({
          url: settings.method === 'POST' ? settings.url + '?' + _localHttpParameters : settings.url,
          data: _data,
          type: settings.method,
          contentType: settings.contentType,
          dataType: settings.dataType,
          processData: false,
//	            	headers: {
//	            		Accept: settings.dataType,
//	            	},
          statusCode: {
            /* Request is OK */
            200: function (data, statusText, jqXHR) {
              if (settings.method === 'POST') {
                post200(data, statusText, jqXHR);
              }
              else if (settings.method === 'GET') {
                get200(data, statusText, jqXHR);
              }
            },
            /* Resource has been created and is available under the URL specified in the location header */
            201: function (data, statusText, jqXHR) {
              if (settings.method === 'POST') {
                post201(data, statusText, jqXHR);
              }
              else if (settings.method === 'GET') {
                pluginError('Sorry, I cannot handle 201 status codes as response to a GET request.');
              }
            },
            /* Request is accepted */
            202: function (data, statusText, jqXHR) {
              if (settings.method === 'POST') {
                post200(data, statusText, jqXHR);
              }
              else if (settings.method === 'GET') {
                get200(data, statusText, jqXHR);
              }
            },
//						202: function (data, statusText, jqXHR) {
//							if (settings.method === 'POST') {
//								pluginError('Sorry, I cannot handle 202 status codes.');
//							}
//							else if (settings.method === 'GET') {
//							  pluginError('Sorry, I cannot handle 202 status codes.');
//							}
//						},
            207: function (data, statusText, jqXHR) {
              if (settings.method === 'POST') {
                post207(data, statusText, jqXHR);
              }
              else if (settings.method === 'GET') {
                pluginError('Sorry, I cannot handle 207 status codes as response to a GET request.');
              }
            }
          }
        })
          .fail(function (jqXHR, statusText, errorThrown) {
            /* TODO: remove hidden identity fields from form! */

            if (settings.method === 'POST') {
              if (jqXHR.status >= 400 && jqXHR.status <= 499) {
                post4xx(jqXHR, statusText, errorThrown);
              }
              else if (jqXHR.status >= 500 && jqXHR.status <= 599) {
                post5xx(jqXHR, statusText, errorThrown);
              }
            }
            else if (settings.method === 'GET') {
              get4xx5xx(jqXHR, statusText, errorThrown);
            }
          });
      };

      /*
       *  The following methods are responsible to handle the received
       *  response, depending on the HTTP method of the request and the
       *  HTTP status code.
       */

      var get200 = function (data, statusText, jqXHR) {
        /* TODO: remove hidden identity fields from form! */

        /* create searchable jQuery object */
        var $window = $(data.markup.trim());

        /* INIT-GET-SUCCESS: open the window and keep it open */
        if ($window.length) {

          if (settings.mode === 'modal') {
            var $body = $('body');
            $window.appendTo($body);
            settings.windowID = $window.prop('id');
            /* reassign standard events to ajax loaded elements */
            stopAnimation();
            /* now delete window */
            if (typeof $body.data('awux-modal') !== 'undefined' && $body.data('awux-modal') !== null) {
              $body.data('awux-modal').remove();
            }
            assignEvents($window);
            window.location.hash = '';
            showWindow($window);
          }
          else if (settings.mode === 'inlet') {
            var $jswin = $('#' + settings.insertID + '_target');
            $jswin.html($window);
            /* reassign standard events to ajax loaded elements */
            assignEvents(settings.insertID + '_target');
          }
        }
        else {
          pluginError('GET 200 response has no markup.');
        }
      };

      var get4xx5xx = function (jqXHR, statusText, errorThrown) {
        /* INIT-GET-FAIL: just close window with loading animation and fade alert */
        var al = $.parseJSON(jqXHR.responseText).alert[0];
        showAlerts(al);

        stopAnimation();

        var $body = $('body');
        if (typeof $body.data('awux-modal') !== 'undefined' && $body.data('awux-modal') !== null) {
          showWindow($body.data('awux-modal'));
        }
      };

      var post200 = function (data, statusText, jqXHR) {
        /* TODO: remove hidden identity fields from form! */

        /* create searchable jQuery object */
        var $window = $(data.markup);
        var _index;

        /* INIT-POST-SUCCESS: we can close the window */
        if ($window.length) {
          /* replace elements in current page by elements from response */
          for (_index in settings.replace) {
            var _inletTargetID = settings.replace[_index];
            var _targetContent = $($window).find(_inletTargetID);
            /* be nice if the response doesn't contain IDs promised in the replace list */
            if (_targetContent.length !== 0) {
              $(_inletTargetID).replaceWith(_targetContent);
            }
            //var _targetContent = $();
          }
        }

        /* If the response has an HTTP 'location' header, redirect the browser. */
        stopAnimation();
        if (typeof jqXHR.getResponseHeader('location') !== 'undefined' && jqXHR.getResponseHeader('location') !== null) {
          window.location.href = jqXHR.getResponseHeader('location');
          /* if window.location.href doesn't change except of hash, page is not loaded again; if new location contains hash, fake user click */
          awuxHashLoader();
        }
        else {
          /* Rredirect the browser to the current 'location'!
           * This is a work-around to avoid syncing/refreshing certain parts of the current DOM
           * However, if
           */
          window.location.href = window.location.href.replace(window.location.hash, '');
          /* we do not want the hash path, if there is one, because it would auto-load a modal */
          window.location.reload();
        }

        /* reassign other events to newly inserted elements */
        for (_index in settings.replace) {
          assignEvents(settings.replace[_index].substring(1));
        }
        /* fade SUCCESS alert */
        showAlerts(data.alert[0]);
      };

      var post201 = function (data, statusText, jqXHR) {
        /* If the response has an HTTP 'location' header, redirect the browser. */
        stopAnimation();
        if (typeof jqXHR.getResponseHeader('location') !== 'undefined' && jqXHR.getResponseHeader('location') !== null) {
          window.location.href = jqXHR.getResponseHeader('location');
          /* if window.location.href doesn't change except of hash, page is not loaded again; if new location contains hash, fake user click */
          awuxHashLoader();
        }
        else {
          /* Rredirect the browser to the current 'location'!
           * This is a work-around to avoid syncing/refreshing certain parts of the current DOM
           * However, if
           */
          window.location.href = window.location.href.replace(window.location.hash, '');
          /* we do not want the hash path, if there is one, because it would auto-load a modal */
          window.location.reload();
        }
        /* fade SUCCESS alert */
        showAlerts(data.alert[0]);
      };

      var post207 = function (data, statusText, jqXHR) {
        /* TODO: If there are many resources and any of them failed, server returns a 207, which means that results are mixed, where each resource may have caused a different status code (e.g. 200, 201, 403, 404, 409) */
        /*
         * for each 2xx: do nothing, for bulk resources the goodies are ignored, this is about the bad guys;
         * for each 4xx:
         * 	If alert is referencing an element in the response markup, mark that element using has-feedback-danger, else show alert in alert-box?
         *
         */

        /* INIT-GET-FAIL: just close window with loading animation and fade alert */
        showAlerts(data.alert);

        stopAnimation();

        var $body = $('body');
        if (typeof $body.data('awux-modal') !== 'undefined' && $body.data('awux-modal') !== null) {
          showWindow($body.data('awux-modal'));
        }
      };

      var post4xx = function (jqXHR, statusText, errorThrown) {
        /* INIT-POST-FAIL 4xx: */
        /* create searchable jQuery object */
        var $window = $(jQuery.parseJSON(jqXHR.responseText).markup);
        var $jswin;
        /* show response */
        var $body = $('body');
        if ($window.length > 0) {
          if (settings.mode === 'modal') {
            $window.appendTo($body);
            settings.windowID = $window.prop('id');
            /* now delete window */
            if (typeof $body.data('awux-modal') !== 'undefined' && $body.data('awux-modal') !== null) {
              $body.data('awux-modal').remove();
            }
            /* reassign standard events to ajax loaded elements */
            assignEvents($window);
            stopAnimation();
            window.location.hash = '';
            showWindow($window);
          }
          else if (settings.mode === 'inlet') {
            $jswin = $('#' + settings.insertID + '_target');
            $jswin.html($window);
            /* reassign standard events to ajax loaded elements */
            assignEvents(settings.insertID + '_target');
          }
        }
        else {
          var al;
          if (settings.mode === 'modal') {
            if (typeof $body.data('awux-modal') !== 'undefined' && $body.data('awux-modal') !== null) {
              $window = $body.data('awux-modal');
              /* TODO: If alert ID is contained in a data-awux-alerts attribute, add class .has-feedback-danger
               * else (e.g. 403) show alert in alert-box */
              var fadeAlert = true;
              $.parseJSON(jqXHR.responseText).alert.forEach(function (al) {
                $window.find('div[data-awux-alerts*="' + al.id + '"]').each(function (index, element) {
                  $(element).addClass('has-' + al.level);
                  $(element).find('span.help-block').html(al.title);
                  if ($window.find('div[data-awux-alerts*="' + al.id + '"]').length > 1) {
                    /* found at least one alert that can be shown in the window, so we don't need to show a fading alert */
                    fadeAlert = false;
                  }
                });
              });
              if (fadeAlert) {
                al = $.parseJSON(jqXHR.responseText).alert[0];
                showAlerts(al);
              }
              stopAnimation();
              showWindow($window);
            }
            else {
              /* there is no awux-modal currently;
               * stop the animation, then get the first alert object from response JSON and show it */
              stopAnimation();
              al = $.parseJSON(jqXHR.responseText).alert[0];
              showAlerts(al);
            }
          }
          else if (settings.mode === 'inlet') {
            $jswin = $('#' + settings.insertID + '_target');
            $jswin.html($window);
            /* reassign standard events to ajax loaded elements */
            assignEvents(settings.insertID + '_target');
          }
        }

        /* TODO: how do i know if there were no elements selected? */
      };

      var post5xx = function (jqXHR, statusText, errorThrown) {
        /* do the same as for GET 5xx: */
        get4xx5xx(jqXHR, statusText, errorThrown);
      };

      /*
       * Methods called by the sequences in the response handlers
       */

      var cleanEditors = function () {
        /* Before submit, update all editor instances and then destroy them! */
        for (var instance in CKEDITOR.instances) {
          CKEDITOR.instances[instance].updateElement();
//					CKEDITOR.instances[instance].destroy(); /* Not sure, if current version of CKEditor still needs this? */
        }
      };

      var showWindow = function ($window) {
        if (settings.mode === 'modal') {
          $('body').data('awux-modal', $window);
          $window.find('.modal-body').css('max-height', $(window).height() * 0.6);
          /* try to fix height issue, see #777 and #466, doesn not work with screen orientation change or resize */
          if (settings.method === 'GET') {
            window.location.hash = settings.url;
          }
          $window.on('hidden.bs.modal', function (event) {
            window.location.hash = '';
          });
          $window.modal('show');
        }
        else if (settings.mode === 'inlet') {
          $('<div id="' + settings.insertID + '_target"><i class="' + settings.spinnerCSS + '"/></div>').hide().insertAfter($insert);
          $('#' + settings.insertID).hide('fade', settings.duration);
          $('#' + settings.insertID + '_target').show('fade', settings.duration);
        }
      };

      var startAnimation = function () {
        /* start loading animation */
        if (settings.mode === 'modal') {
          $('<div id="awux-window-animated-modal" class="modal ' + settings.fade + '" tabindex="-1" role="dialog" aria-labelledby="awux-window-animated-modal-label" aria-hidden="true"><div class="modal-dialog"><div id="awux-window-animated-modal-content" class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button><h4 class="modal-title" id="awux-window-animated-modal-label">Loading. Please wait...</h4></div><div class="modal-body"><div id="awux-window-animated-modal-spinner"><center><i class="' + settings.spinnerCSS + '"/></center></div></div><div class="modal-footer"></div></div></div></div>').appendTo($('body'));
          $('#awux-window-animated-modal').modal('show');
        }
        else if (settings.mode === 'inlet') {
          $('<div id="' + settings.insertID + '_target"><i class="' + settings.spinnerCSS + '"/></div>').hide().insertAfter($insert);
          $('#' + settings.insertID).hide('fade', settings.duration);
          $('#' + settings.insertID + '_target').show('fade', settings.duration);
        }
      };

      var stopAnimation = function () {
        /* stop loading animation */
        if (settings.mode === 'modal') {
          var $modal = $('#awux-window-animated-modal');
          $modal.modal('hide');
          $modal.remove();
        }
        else if (settings.mode === 'inlet') {
          var $target = $('#' + settings.insertID + '_target');
          $target.hide('fade', settings.duration);
          $target.remove();
          $('#' + settings.insertID).show('fade', settings.duration);
          $(settings.this).show('fade', settings.duration);
          /* #TODO: what is this doing? showing the button?!? */
        }
      };

    });
  };
})(jQuery);

