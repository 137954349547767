/*
* 
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap.js by @fat & @mdo
* @author AniX (anh@hatzis.de)
* @version 0.1
*/
var showAlerts = require('./utils.js').showAlerts;
var assignEvents = require('./events.js').assignEvents;
var awuxHashLoader = require('./awuxHashLoader.js').awuxHashLoader;

/* JSWM-Instant is a plugin that works for a single <input>, <textarea>, or <select> in a form. It represents a property of the outer entity object.
 * In some cases (e.g. input.auto-search) the plugin observes not the visible element but a hidden element which stores the actual value.
 * When user changes such element's value, the plugin will try to instantly update the property on the server 
 * NOTE: For implementing this feature we bind an observed element to jQuery's change event. For select boxes, checkboxes and radio buttons
 * the change event is fired immediately when the user makes a selection, but for the other elements the event is deferred until the element loses focus.
 */
;(function ($) {
  $.fn.awuxInstant = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes
       * format tells plugin if it is a date, time, or datetime picker
       */
      var settings = $.extend({
        'method': typeof $(this).attr('awux-method') !== 'undefined' ? $(this).attr('awux-method').toUpperCase() : 'POST',
        'url': $(this).data('awux-url'),
        'contentType': 'application/json;charset=utf-8', /* the content-type in which we send the data to server */
        'dataType': 'json', /* the content-type of the response we expect from the server */
        'httpParameters': $.parseParams((window.location.href.indexOf('?') !== -1) ? window.location.href.slice(window.location.href.indexOf('?') + 1) : ''),
        'duration': 250, /* duration of animation effects in ms */
        'this': $(this)
      }, options);
      /*
       * Now bind all relevant form elements to the change event; Eventually for some controls we need to store the local value and have the plugin compare by itself at blur()!
       * Note that for some elements (e.g. auto-search uses a hidden input) this is not the same element as this; this is the element the user can see
       */
      $(settings.this).find('input, textarea, select').not('input.auto-select').not('input.auto-search').each(function (index, element) {
        /* TODO: do we need to filter out elements belonging to different form? .not('form!="'+settings.this.attr('id')+'"') */
        $(element).change(function (event) {
          /* first, we need to turn off the default event handler on click */
          event.preventDefault();
          /* is this element the element where we want to show the animations? */
          var animatedID;
          if (typeof $(this).data('represented') !== 'undefined') {
            animatedID = $(this).data('represented');
            /* TODO: maybe it would be better if we could avoid using HTML IDs for the animation stuff, but I think icons, form-group and buttons may vary */
          }
          else {
            animatedID = $(this).attr('id');
          }
          var $feedbackIcon = $('#' + animatedID + '_FeedbackIcon');
          var $formGroup = $('#' + animatedID + '_FormGroup');
          var $button = $('#' + animatedID + '_Button');
          /* remove help-text (eventually there is error of previous POST); */
          $formGroup.find('span.help-block').html('');
          /* show spinner animation */
          $feedbackIcon.removeClass('fa-check fa-times', settings.duration).addClass('fa-spinner fa-spin', settings.duration);
          $formGroup.removeClass('has-success has-error', settings.duration);
          $button.removeClass('btn-success btn-danger', settings.duration);

          /* Prepare the initial AJAX request */
          var arr = $.map(settings.httpParameters, function (val, key) {
            return {name: key, value: val};
          });
          var _localHttpParameters = $.param(arr);
          /* get all the data from the form to which the button belongs to */
          var _jsonData = JSON.stringify(settings.this.serializeObject());

          /* Execute the AJAX request */
          $.ajax({
            url: settings.url + '?' + _localHttpParameters,
            data: _jsonData,
            type: settings.method,
            contentType: settings.contentType,
            dataType: settings.dataType,
//	                headers: {
//	                	Accept: settings.dataType,
//	                },
            statusCode: {
              /* Request is OK */
              200: function (data, statusText, jqXHR) {
                if (settings.method === 'POST') {
                  /* stop loading animation, start success animation, then back to default: */
                  $formGroup.addClass('has-success', settings.duration);
                  $button.addClass('btn-success', settings.duration);
                  $feedbackIcon.removeClass('fa-spinner fa-spin', settings.duration).addClass('fa-check', settings.duration);
                  $formGroup.delay(3 * settings.duration);
                  $button.delay(3 * settings.duration);
                  $feedbackIcon.delay(3 * settings.duration);
                  $formGroup.removeClass('has-success', settings.duration);
                  $button.removeClass('btn-success', settings.duration);
                  $feedbackIcon.removeClass('fa-check', settings.duration);

                  post200(data, statusText, jqXHR);
                }
              },
              /* Resource has been created and is available under the URL specified in the location header */
              201: function (data, statusText, jqXHR) {
                post201(data, statusText, jqXHR);
                /* see if response header has 'location' */
              }
            }
          })
            .fail(function (jqXHR, textStatus, errorThrown) {
              var json;
              if (jqXHR.status >= 400 && jqXHR.status <= 499) {
                /* POST-FAIL 4xx: show response */
                /* TODO:
                 * replace JSON data in page from JSON response, alerts?
                 */
                json = $.parseJSON(jqXHR.responseText);
                $formGroup.find('span.help-block').html(json.alert[0].title);

                /* stop loading animation and enable buttons */
                $formGroup.addClass('has-error', settings.duration);
                $button.addClass('btn-danger', settings.duration);
                $feedbackIcon.removeClass('fa-spinner fa-spin', settings.duration).addClass('fa-times', settings.duration);
              }
              else if (jqXHR.status >= 500 && jqXHR.status <= 599) {
                  /* POST-FAIL 5xx: */
                  /* fade ERROR alert */
                  json = $.parseJSON(jqXHR.responseText);
                  showAlerts(json.alert[0]);
                  /* stop loading animation and enable buttons */
                  $formGroup.addClass('has-error', settings.duration);
                  $button.addClass('btn-danger', settings.duration);
                  $feedbackIcon.removeClass('fa-spinner fa-spin', settings.duration).addClass('fa-times', settings.duration);
                }
            });
        });

        var post201 = function (data, statusText, jqXHR) {
          /* fade SUCCESS alert */
          showAlerts(data.alert[0]);

          /* If the response has an HTTP 'location' header, redirect the browser. */
          if (typeof jqXHR.getResponseHeader('location') !== 'undefined' && jqXHR.getResponseHeader('location') !== null) {
            window.location.href = jqXHR.getResponseHeader('location');
            /* if window.location.href doesn't change except of hash, page is not loaded again; if new location contains hash, fake user click */
            awuxHashLoader();
          }
          else {
            /* Rredirect the browser to the current 'location'!
             * This is a work-around to avoid syncing/refreshing certain parts of the current DOM
             * However, if
             */
            window.location.href = window.location.href.replace(window.location.hash, '');
            /* we do not want the hash path, if there is one, because it would auto-load a modal */
            window.location.reload();
          }
        };

        var post200 = function (data, statusText, jqXHR) {
          /* create searchable jQuery object */
//            	console.log(data);
//            	var json = $.parseJSON(data);
//            	console.log(json);
//            	console.log(data.payload);

          /* TODO: from JSON in response, do update of internal data and form element values
           *
           * update internal JSON data
           * update form element values
           *
           */

          /* refresh prevoious-value attributes of form (and how of the other forms?) */
          $(settings.this).find('fieldset').find('input').each(function (index, element) {
            $(element).val($(element).val());
            $(element).data('previous-value', $(element).val());
          });

          /* fade SUCCESS alert */
          showAlerts(data.alert[0]);

          /* If the response has an HTTP 'location' header, redirect the browser. */
          if (typeof jqXHR.getResponseHeader('location') !== 'undefined' && jqXHR.getResponseHeader('location') !== null) {
            window.location.href = jqXHR.getResponseHeader('location');
            /* if window.location.href doesn't change except of hash, page is not loaded again; if new location contains hash, fake user click */
            awuxHashLoader();
          }
          /* different from other response implementations, awux-instant will not reload documents after POST */

          /* #838: redirect the browser to the current 'location'
           * this is a work-around to avoid refreshing certain parts of the current DOM
           */
//				window.location.reload();
          /* turned off page refresh for instant forms, because it normally doesn't make sense */

          /* reassign other events to newly inserted elements */
          for (var _index in settings.replace) {
            assignEvents(settings.replace[_index].substring(1));
          }
        };
      });
      /* Change input to spinner version, CSS to has-warning, icon to "fa-spinner fa-spin" */
      /* send  AJAX request to resource e.g. POST /prodplant/1/order/59435/head */

    });
    /*
     * first item in sequence is a test-op to verify that version id of entity has not changed
     * if new value is empty: delete-op else update-op
     * If ERROR:
     *  remove "fa-spinner fa-spin", remove "has-warning"
     *  CSS to has-error and icon to fa-times
     *  show error message as help text
     * If SUCCESS:
     *  remove "fa-spinner fa-spin", remove "has-warning"
     *  CSS to has-success and icon to fa-check, for a short time and automatically remove has-success
     */
  };
})(jQuery);

