var showAlerts = require('./utils.js').showAlerts;
var awuxHashLoader = require('./awuxHashLoader.js').awuxHashLoader;

;(function ($) {
  $.fn.awuxSwitch = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      /* overwrite settings by setting options, e.g. $('#foo').awuxSwitch({'duration':500}) */
      var settings = $.extend({
        'method': typeof $(this).attr('awux-method') !== 'undefined' ? $(this).attr('awux-method').toUpperCase() : 'POST',
        'url': $(this).data('awux-url'),
        'contentType': 'application/json;charset=utf-8', /* the content-type in which we send the data to server */
        'dataType': 'json', /* the content-type of the response we expect from the server */
        'httpParameters': $.parseParams((window.location.href.indexOf('?') !== -1) ? window.location.href.slice(window.location.href.indexOf('?') + 1) : ''),
        'duration': 250, /* duration of animation effects in ms */
        'this': $(this)
      }, options);
      $(this).find('a.awux-form-enable').on('click', function (event) {
        /* first, we need to turn off the default event handler on click */
        event.preventDefault();
        /* store all old values of form */
        $(settings.this).find('fieldset').find('input').each(function (index, element) {
          $(element).data('previous-value', $(element).val());
        });
        $(settings.this).find('fieldset').find('input, textarea, select').prop('disabled', false);
        $(settings.this).find('a.awux-form-enable').fadeOut(settings.duration);
        $(settings.this).find('button[type="submit"]').fadeIn(settings.duration).find('i').removeClass('fa-spinner fa-spin').addClass('fa-save', settings.duration);
        $(settings.this).find('button[type="reset"]').fadeIn(settings.duration);
      });
      $(this).find('button[type="reset"]').on('click', function (event) {
        /* first, we need to turn off the default event handler on click */
        event.preventDefault();
        /* restore all old values of form */
        $(settings.this).find('fieldset').find('input').each(function (index, element) {
          $(element).val($(element).data('previous-value'));
        });
        $(settings.this).find('fieldset').find('input, textarea, select').prop('disabled', true);
        $(settings.this).find('button[type="submit"]').find('i').removeClass('fa-spinner fa-spin').addClass('fa-save', settings.duration);
        $(settings.this).find('button[type="submit"]').fadeOut(settings.duration);
        $(settings.this).find('button[type="reset"]').fadeOut(settings.duration);
        $(settings.this).find('a.awux-form-enable').fadeIn(settings.duration);
      });
      $(this).find('button[type="submit"]').on('click', function (event) {
        /* first, we need to turn off the default event handler on click */
        event.preventDefault();
        /* disable the buttons first */
        $(settings.this).find('button[type="submit"]').prop('disabled', true);
        $(settings.this).find('button[type="reset"]').prop('disabled', true);
        $(this).find('i').removeClass('fa-save').addClass('fa-spinner fa-spin', settings.duration);

        /* Prepare the initial AJAX request */
        var arr = $.map(settings.httpParameters, function (val, key) {
          return {name: key, value: val};
        });
        var _localHttpParameters = $.param(arr);
        /* get all the data from the form to which the button belongs to */
        var _jsonData = JSON.stringify(settings.this.serializeObject());

        /* Execute the AJAX request */
        $.ajax({
          url: _localHttpParameters.length > 0 ? settings.url + '?' + _localHttpParameters : settings.url,
          data: _jsonData,
          type: settings.method,
          contentType: settings.contentType,
          dataType: settings.dataType,
//                  headers: {
//                  	Accept: settings.dataType,
//                  },
          statusCode: {
            /* Request is OK */
            200: function (data, statusText, jqXHR) {
              post200(data, statusText, jqXHR);
              /* see if response header has 'location' */
            },
            /* Resource has been created and is available under the URL specified in the location header */
            201: function (data, statusText, jqXHR) {
              post201(data, statusText, jqXHR);
              /* see if response header has 'location' */
            }
          }
        })
          .fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status >= 400 && jqXHR.status <= 499) {
              /* POST-FAIL 4xx: show response */
              /* TODO:
               * replace JSON data in page from JSON response, alerts?
               */
              $.parseJSON(jqXHR.responseText).alert.forEach(function (al) {
                var $dialog = $('#' + settings.windowID + '-dialog');
                $dialog.find('div[data-awux-alerts*="' + al.id + '"]').each(function (index, element) {
                  $(element).find('button.dropdown-toggle').addClass((al.level === 'error') ? 'btn-danger' : al.level);
                  $(element).addClass('has-' + al.level);
                  $(element).find('span.help-block').html(al.title);
                });
                if ($dialog.find('div[data-awux-alerts*="' + al.id + '"]').length === 0) {
                  /* no HTML element found to which the alert might belong... so add it alert in a small alert-box on top of modal body: */
                  showAlerts(al);
                }
              });

              /* stop loading animation and enable buttons */
              $(settings.this).find('button[type="submit"]').find('i').removeClass('fa-spinner fa-spin').addClass('fa-save', settings.duration);
              $(settings.this).find('button[type="submit"]').prop('disabled', false);
              $(settings.this).find('button[type="reset"]').prop('disabled', false);
            }
            else if (jqXHR.status >= 500 && jqXHR.status <= 599) {
                /* POST-FAIL 5xx:
                 * fade ERROR alert
                 */
                showAlerts({
                  'code': jqXHR.status,
                  'title': jqXHR.statusText,
                  'level': 'error'
                });
                /* stop loading animation and enable buttons */
                $(settings.this).find('button[type="submit"]').find('i').removeClass('fa-spinner fa-spin').addClass('fa-save', settings.duration);
                $(settings.this).find('button[type="submit"]').prop('disabled', false);
                $(settings.this).find('button[type="reset"]').prop('disabled', false);
              }
          });
      });

      var post201 = function (data, statusText, jqXHR) {
        /* fade SUCCESS alert */
        showAlerts(data.alert[0]);

        /* If the response has an HTTP 'location' header, redirect the browser. */
        if (typeof jqXHR.getResponseHeader('location') !== 'undefined' && jqXHR.getResponseHeader('location') !== null) {
          window.location.href = jqXHR.getResponseHeader('location');
          /* if window.location.href doesn't change except of hash, page is not loaded again; if new location contains hash, fake user click */
          awuxHashLoader();
        }
        else {
          /* Rredirect the browser to the current 'location'!
           * This is a work-around to avoid syncing/refreshing certain parts of the current DOM
           * However, if
           */
          window.location.href = window.location.href.replace(window.location.hash, '');
          /* we do not want the hash path, if there is one, because it would auto-load a modal */
          window.location.reload();
        }
      };

      var post200 = function (data, statusText, jqXHR) {
        /* create searchable jQuery object */
//            	console.log(data);
//            	var json = $.parseJSON(data);
//            	console.log(json);
//            	console.log(data.payload);

        /* fade SUCCESS alert */
        showAlerts(data.alert[0]);

        /* INIT-POST-SUCCESS: we can close the window */

        /* stop loading animation */
        $(settings.this).find('button[type="submit"]').find('i').removeClass('fa-spinner fa-spin').addClass('fa-save', settings.duration);

        /* disable fieldset, hide buttons, show link */
        $(settings.this).find('fieldset').find('input, textarea, select').prop('disabled', true);
        $(settings.this).find('button[type="submit"]').prop('disabled', false);
        $(settings.this).find('button[type="reset"]').prop('disabled', false);
        $(settings.this).find('button[type="submit"]').fadeOut(settings.duration);
        $(settings.this).find('button[type="reset"]').fadeOut(settings.duration);
        $(settings.this).find('a.awux-form-enable').fadeIn(settings.duration);

        /* TODO: from JSON in response, do update of internal data and form element values
         * update internal JSON data
         * update form element values
         */

        /* refresh prevoious-value attributes of form (and how of the other forms?) */
        $(settings.this).find('fieldset').find('input').each(function (index, element) {
          $(element).val($(element).val());
          $(element).data('previous-value', $(element).val());
        });

        /* If the response has an HTTP 'location' header, redirect the browser. */
        if (typeof jqXHR.getResponseHeader('location') !== 'undefined' && jqXHR.getResponseHeader('location') !== null) {
          window.location.href = jqXHR.getResponseHeader('location');
          /* if window.location.href doesn't change except of hash, page is not loaded again; if new location contains hash, fake user click */
          awuxHashLoader();
        }
        else {
          /* Rredirect the browser to the current 'location'!
           * This is a work-around to avoid syncing/refreshing certain parts of the current DOM
           * However, if
           */
          window.location.href = window.location.href.replace(window.location.hash, '');
          /* we do not want the hash path, if there is one, because it would auto-load a modal */
          window.location.reload();
        }
      };
    });
  };
})(jQuery);
