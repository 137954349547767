/*global CKEDITOR */

/* Avoid "console" reference errors for users that have no firebug or chrome */
if (typeof console === 'undefined') {
  window.console = {};
  console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = function () {
    /* TODO: store console logs in a variable that can be shown or sent to server if needed? */
  };
}

/* enable jquery migrate warnings in the console, please report if you see one */
jQuery.migrateMute = false;

require('./parseParams.jq.js');

require('./awuxFormFileUploader.jq.js');

require('./awuxImageCropIt.jq.js');

var fadingAlert = require('./utils.js').fadingAlert;




;(function ($) {
  $.fn.editor = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'oldValue': $(this).val(),
        'config': '/static/plugins/ckeditor-configs/' + $(this).data('config') + '.js',
        'this': $(this)
      }, options);

//		    CKEDITOR.replace( $(this).attr('id'), {
//		    	customConfig: settings.config
//		    }, settings.content );

      var $body = $('body');
      $(this).ckeditor({
        /* Define changes to default configuration here.
        * For complete reference see:
        * http://docs.ckeditor.com/#!/api/CKEDITOR.config
        */

        height: 280,
        // Add plugins providing functionality popular in BBCode environment.
        extraPlugins: 'bbcode,smiley,colorbutton,autogrow,embedsemantic,maximize',
        // Remove unused plugins.
        removePlugins: 'filebrowser,font,format,horizontalrule,pastetext,pastefromword,scayt,showborders,stylescombo,table,tabletools,wsc',
        // Remove unused buttons.
        removeButtons: 'Anchor,BGColor,Font,Strike,Subscript,Superscript',
        // Width and height are not supported in the BBCode format, so object resizing is disabled.
        disableObjectResizing: true,
        // Define font sizes in percent values.
        fontSize_sizes: "30/30%;50/50%;100/100%;120/120%;150/150%;200/200%;300/300%",
        // Strip CKEditor smileys to those commonly used in BBCode.
        smiley_images: [
          'regular_smile.png', 'sad_smile.png', 'wink_smile.png', 'teeth_smile.png', 'tongue_smile.png',
          'embarrassed_smile.png', 'omg_smile.png', 'whatchutalkingabout_smile.png', 'angel_smile.png',
          'shades_smile.png', 'cry_smile.png', 'kiss.png'
        ],
        smiley_descriptions: [
          'smiley', 'sad', 'wink', 'laugh', 'cheeky', 'blush', 'surprise',
          'indecision', 'angel', 'cool', 'crying', 'kiss'
        ],

        /* set allowedContent to true and overwrite contents.css with the bootstrap.css, so the editor's content
         * shows the same styles as the site
         */
        contentsCss: [CKEDITOR.basePath + 'contents.css', $body.data('awux-theme')],
        allowedContent: true,

        dialog_backgroundCoverOpacity: 0.9, /* the opacity of the teardrop color of the editor's modals' */

        language: $body.data('awux-user').options.lang,
        toolbarCanCollapse: true,
        toolbarStartupExpanded: true,

        skin: 'moono-awux',

        /* the API URL to send URLs for oembed, see  http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-embed_provider */
        /* This API key is for Motor only!!! */
        embed_provider: 'https://iframe.ly/api/oembed?url={url}&callback={callback}&api_key=1a3a9ce9e5548bda23768b'

      }).editor.on('blur', function (event) {
        /* save editor's value in <textarea> as soon as the editable area looses focus
         * this allows our forms to simply include the textarea's value when submitting
        */
        var oldValue = settings.oldValue;
        /* old value is store in settings */
        var newValue = settings.this.val();
        /* current value of the editor */
        if (oldValue !== newValue) {
          /* CKeditor automatically copies its current value into <textarea> */
          settings.oldValue = newValue;
          /* reset value of settings, so comparison will work with future blur events */
          /* now trigger change() manually, because using val() does not trigger the event automatically -
           * but for some unknown reason, I need to get the <textarea> by its ID,
           * using $(this).element has no effect;
           */
          console.log('textarea.val():' + settings.this.val());
          settings.this.change();
        }
      });
    });
  };
})(jQuery);


require('./awuxLine.js');

require('./awuxForm.js');

require('./autosearch.jq.js');

require('./autoselect.jq.js');

require('./awuxWindow.jq.js');
require('./awuxInstant.jq.js');

require('./awuxSwitch.jq.js');

require('./awuxFilter.jq.js');
require('./awuxChannel.jq.js');
require('./awuxController.jq.js');

require('./awuxView.jq.js');

require('./erp.jq.js');

var awuxHashLoader = require('./awuxHashLoader.js').awuxHashLoader;

var assignEvents = require('./events.js').assignEvents;

/*
* User Object, implements methods and attributes 
* that are globally used throughout the page; will be stored in
* var $body = $('body');
* $body.data('awux-user');
* @usage
* var User = new user({ setMobileCall : 'call.php', useEditor : true, askEditor : true });
* @depends on
* ~ jQuery    1.9.1
* @author Maximilian Orth (maxx.orth@gmail.com)
* @version 1.0.0
*/

var user = function (options) {
  /* save user options */
  this.options = $.extend({
    'showMessages': true,
    'confirmCK': 'Use full CK-Editor on this mobile device?',
    'successCK': 'Successfully saved your decision',
    'errorCK': 'Error saving your decision'
  }, options);
};

/*
 * userObj is needed for patched CKeditor, see #400
 * ask user about Mobile Usage of CK editor
 */
user.prototype.askEditor = function () {
  var _this = this;

  if (this.options.askEditor) {
    _this.options.useEditor = confirm(_this.options.confirmCK);
    $.ajax({
      url: _this.options.setMobileCall,
      data: {useEditor: _this.options.useEditor}
    })
      .done(function () {
        if (_this.options.showMessages) {
          fadingAlert(_this.options.successCK);
        }
      })
      .fail(function () {
        if (_this.options.showMessages) {
          fadingAlert(_this.options.errorCK);
        }
      });
    return true;
  }
  else {
    return false;
  }
};

window.user = user;

$(document).ready(function () {
//	console.log('document ready deletes cached templates');

  window.awuxUiVersion = $('#awux-app').data('awux-ui-version');
  var $awuxSession = $('#awux-session');
  var sessionData = $awuxSession.data('awux-jsons');
  var $awuxChannel = $('#awux-channel');
  var $body = $('body');
  $body.data('awux-user', new user({
    id: sessionData.id,
    setMobileCall: sessionData.setMobileCall,
    useEditor: sessionData.useEditor,
    askEditor: sessionData.askEditor,
    confirmCK: $awuxSession.data('awux-confirmCK'),
    formatDatetime: sessionData.formatDatetime,
    tzname: sessionData.tzname,
    lang: sessionData.language,
    trackerWithUserID: sessionData.trackerWithUserID
  }));

  if ($awuxChannel.length) {
    /*
      do not flash the site logo if there is no channel,
      because only a successful channel connection could stop
      the logo from flashing
     */
    $('#awux-brand').addClass('awux-flash');
  }
  $body.data('awux-modal', null);
  $body.data('awux-registry', {});
  /* empty cache / local storage of items we want to listen to and handle events */
  $body.data('awux-templates', {});
  /* empty cache / local storage of mustache templates */
  $body.data('awux-autosearch-forms-counter', 0);
  /* counter for HTML IDs of all forms automatically created by autosearch plugin */
  $body.data('awux-autoselect-forms-counter', 0);
  /* counter for HTML IDs of all forms automatically created by autoselect plugin */
  assignEvents($body);
  /* if URL hash starts with '/', directly GET the modal and show it, by temporarily adding an auto-loader button to DOM so awuxModal plugin's features can be re-used */
  awuxHashLoader();
  if ($awuxChannel.length) {
    /* Optional element in page that contains the token for Channel API of Google App Engine */
    $awuxChannel.awuxChannel();
  }
});
