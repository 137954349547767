/* global Mustache */
/*
* AWUX View is a very simple plugin to asynchronously get from a server all
* mustache templates defined in a page and then render them when requested
* by other plugins 
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

;(function ($) {
  $.fn.awuxView = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'view': typeof $(this).attr('data-awux-view') !== 'undefined' && $(this).attr('data-awux-view') !== null ? $(this).attr('data-awux-view') : null
      }, options);

      if (typeof settings.view !== 'undefined' && settings.view !== null) {
        // make sure the template is cached, but don't use it
        view_cache.getTemplate(settings.view, function () {
        });
      }

    });
  };
})(jQuery);

/**
 * fetchView fetches a view from the server
 * @param             httpParameters result of $.parseParams
 * @param  {string}   view           the view to fetch
 * @param  {Function} callback       callback to call when the view is fetched
 */
function fetchView(httpParameters, view, callback) {
  var arr = $.map(httpParameters, function (val, key) {
    return {name: key, value: val};
  });
  var _localHttpParameters = $.param(arr);
  $.ajax({
    url: '/ui/compiled/' + window.awuxUiVersion + view + (_localHttpParameters.length > 0 ? '?' + _localHttpParameters : ''),
    type: 'GET',
    ifModified: true, // only if modified, uses etag specified by the server (but jQuery will ignore this anyway, because it deletes cache between page reloads)
    contentType: 'text/html; charset=utf-8',
    success: function (response, statusText, jqXHR) {
      callback(response);
    },
    error: function (response) {
      /* failed request; */
      console.log('failed to load template: ' + response.status + ': ' + response.statusText);
      /* TODO: call callback? */
    }
  });
}

/**
 * ViewCache caches views and makes sure they are only fetched once.
 * @param httpParameters  result of $.parseParams
 */
function ViewCache(httpParameters) {
  this.cache = {};
  this.httpParameters = httpParameters;
}

/**
 * getTemplate gets a template from cache or fetches it and adds it if it's not there.
 * if it's not there, but already being fetched, add the callback as a listener, but don't fetch it again.
 * @param  {string}   view     the view to get
 * @param  {Function} callback function to call when the view is available
 */
ViewCache.prototype.getTemplate = function (view, callback) {
  var cache = this.cache; // 'this' is lost in the other functions
  function run_callback() {
    callback(cache[view].content);
  }

  if (this.cache[view] && this.cache[view].loaded) {
    // already loaded before
    run_callback();
  } else if (!this.cache[view]) {
    // loading or not loaded at all yet
    // add a cache entry and fetch if it's not there yet
    // this calls fetchView before adding the listener, so will fail if fetchView is synchronous
    this.cache[view] = {loaded: false, listeners: []};
    fetchView(this.httpParameters, view, function (response) {
      cache[view].loaded = true;
      cache[view].content = response;
      cache[view].listeners.forEach(function (f) {
        f();
      });
    });
  }
  // add the listener
  this.cache[view].listeners.push(run_callback);
};


var view_cache = new ViewCache($.parseParams((window.location.href.indexOf('?') !== -1) ? window.location.href.slice(window.location.href.indexOf('?') + 1) : ''));

/* 
 * also see awuxView plugin below; when event-bindings are assigned the client tries to preemptively get all templates
 * the AJAX call below is just a fall-back in case the template has been missed
 * */
var render = function (view, data, callback) {
  view_cache.getTemplate(view, function (src) {
    callback($(Mustache.render(src, data).trim()));
  });
};

module.exports.render = render;
