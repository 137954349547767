/* AWUX HeadRemove, LineAdd, and LineRemove plugins play together for dynamically expanding forms in DOM
 *
 * To get these three plugins to work, the markup needs to have three elements nested like this:
 *   <el data-awux-repeat="anchor"></el>
 *   <!-- the anchor is used to store any JSON data to render line elements and to insert "head" before or after the anchor -->
 *   <el data-awux-repeat="head">
 *     <!-- optional custom stuff here -->
 *     <el data-awux-repeat="line">
 *     <!-- optional custom stuff here -->
 *     </el>
 *     <el data-awux-repeat="line">
 *     <!-- optional custom stuff here -->
 *     </el>
 *     <el data-awux-repeat="line">
 *     <!-- optional custom stuff here -->
 *     </el>
 *     <el data-awux-repeat="footer">
*     <!-- optional custom stuff here -->
 *     </el>
 *     <!-- optional custom stuff here -->
 *   </el>
 * There can be multiple "line" siblings, especially when new siblings are added dynamically (before "footer").
 * Each line can contain multiple form elements or whatever is needed per line.
 * The footer should have three buttons to either add new lines or to delete the entire head with all lines, or to submit
 */

var render = require('./awuxView.jq.js').render;
var assignEvents = require('./events.js').assignEvents;

/*
* AWUX Head Remove plugin (bound to a reset-button) removes a head element with all its content (including all line elements).
* The anchor element will remain in DOM though
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

;(function ($) {
  $.fn.awuxHeadRemove = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'head': $(this).closest('[data-awux-repeat="head"]'), /* the header element to which this element belongs */
        'this': $(this)
      }, options);

      /* assign initial action to link */
      $(this).on('click', function (event) {
        onClick(event);
      });

      var onClick = function (event) {

        /* first, we need to turn off the default event handler on click */
        event.preventDefault();

        /* Delete the corresponding line element and its content */
        console.log('clicked "remove head" button');
        /* auto-search and auto-select plugins do create forms that need to be deleted manually */
        settings.head.find('input.auto-search').destroy();
        settings.head.find('input.auto-select').destroy();
        settings.head.remove();
      };
    });
  };
})(jQuery);

/*
* AWUX Line Remove plugin removes a line
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

;(function ($) {
  $.fn.awuxLineAdd = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'head': $(this).closest('[data-awux-repeat="head"]'), /* the header element to which this element belongs */
        'view': $(this).attr('data-awux-view'), /* the view template to use for this new line */
        'this': $(this)
      }, options);

      /* assign initial action to link */
      $(this).on('click', function (event) {
        onClick(event);
      });

      var onClick = function (event) {

        /* first, we need to turn off the default event handler on click */
        event.preventDefault();

        /* Delete the corresponding line element and its content */
        console.log('clicked "add line" button');
        /* head element stores JSON payload to render into the view */
        render(settings.view, settings.head.data('awux-payload'), function (output) {
          /* TODO: show loading indicator */
          /* insert output into DOM before assigning events */
          settings.head.find('[data-awux-repeat="footer"]').before(output);
          assignEvents(output);
        });
      };


    });
  };
})(jQuery);


/*
* AWUX Line Remove plugin removes a line
* @depends on
* ~ jQuery    1.9.1
* ~ Bootstrap  3.1.1
* @author AniX (anh@hatzis.de)
*/

;(function ($) {
  $.fn.awuxLineRemove = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'head': $(this).closest('[data-awux-repeat="head"]'), /* the header element to which this element belongs */
        'line': $(this).closest('[data-awux-repeat="line"]'), /* the line element that must be removed, if this element is clicked */
        'this': $(this)
      }, options);

      /* assign initial action to link */
      $(this).on('click', function (event) {
        onClick(event);
      });

      var onClick = function (event) {

        /* first, we need to turn off the default event handler on click */
        event.preventDefault();

        /* Delete the corresponding line element and its content */
        console.log('clicked "remove line" button');
        /* auto-search and auto-select plugins do create forms that need to be deleted manually */
        settings.line.find('input.auto-search').destroy();
        settings.line.find('input.auto-select').destroy();
        settings.line.remove();
      };


    });
  };
})(jQuery);

