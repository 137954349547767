/*
 * AWUX Image Crop plugin
 * A wrapper for the jQuery CropIt plugin.
 * This wrapper binds to a `div.image-editor` element, which allows a user to
 * choose a file from the local device, zoom and drag the image, so the image
 * is cropped at the given offset and dimensions. When the form with this
 * element is submitted, the image data is part of the payload.
 *
 * The `div.image-editor` element must be included in a form.
 * The `div.image-editor` element must be placed in a page, not modal, if
 * `shape` is 'circle' or 'rounded'.
 */

var showAlerts = require('./utils.js').showAlerts;

;(function ($) {
  $.fn.awuxImageCropIt = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes
       * overwrite options for individual elements in the JS, e.g.:
       *  $("a").awuxWindow({spinnerCSS: "fa fa-refresh fa-spin fa-2x"});
       */
      var settings = $.extend({
        'shape': typeof $(this).data('awux-shape') !== 'undefined' && (($(this).data('awux-shape') === 'square') || ($(this).data('awux-shape') === 'circle') || ($(this).data('awux-shape') === 'rounded')) ? $(this).data('awux-shape') : 'square', /* shape of the preview image: 'square' (default), 'circle' or 'rounded' */
        'exportZoom': typeof $(this).data('awux-export-zoom') === 'undefined' ? 1 : parseInt($(this).data('awux-export-zoom')), /* The ratio between the desired image size to export and the preview size. For example, if the preview size is 300px * 200px, and exportZoom = 2, then the exported image size will be 600px * 400px. This also affects the maximum zoom level, since the exported image cannot be zoomed to larger than its original size. */
        'width': typeof $(this).data('awux-width') === 'undefined' ? 400 : parseInt($(this).data('awux-width')), /* Width of image preview in pixels. If set, it will override the CSS property. Default: null */
        'height': typeof $(this).data('awux-height') === 'undefined' ? 400 : parseInt($(this).data('awux-height')), /* Height of image preview in pixels. If set, it will override the CSS property. Default: null */
        'imageBackground': typeof $(this).data('awux-image-background') !== 'undefined', /* Whether or not to display the background image beyond the preview area. Default: false*/
        'hiddenImageData': $(this).find('.hidden-image-data'), /* the hidden input that will store the image data; referenced here to speed up writing value into this element */
        'awuxFileReaderError': {
          'id': 'AWUX_FileReaderError',
          'code': '',
          'level': 'error',
          'title': 'Fehler beim Laden',
          'body': 'Bitte versuche erneut, die Datei zu übertragen. Falls das Problem weiterhin besteht, überprüfe bitte, ob die Datei eventuell beschädigt ist oder z. B. Sicherheitseinstellungen deines Browsers ein Laden verhindern.',
          'subtitle': 'Fehler',
          'item': []
        }, /* get t9n strings or translated alert objects from server or insert them into templates? */
        'awuxImage0Error': {
          'id': 'AWUX_Image0Error',
          'code': '',
          'level': 'error',
          'title': 'Fehler während Bildverarbeitung',
          'body': 'Es werden nur JPG, WEBP, PNG oder GIF unterstützt.',
          'subtitle': 'Fehler',
          'item': []
        }, /* get t9n strings or translated alert objects from server or insert them into templates? */
        'awuxImage1Error': {
          'id': 'AWUX_Image1Error',
          'code': '',
          'level': 'error',
          'title': 'Ausgewählte Bild zu klein',
          'body': 'Das Bild muss mindestens 400x400 Pixel groß sein.',
          'subtitle': 'Fehler',
          'item': []
        }, /* get t9n strings or translated alert objects from server or insert them into templates? */
        'this': $(this)
      }, options);
//            console.log('awuxImageCropIt shape: ' + settings.shape);
//          console.log('awuxImageCropIt width: ' + settings.width);
//          console.log('awuxImageCropIt height: ' + settings.height);
//          console.log('awuxImageCropIt exportZoom: ' + settings.exportZoom);
//          console.log('awuxImageCropIt imageBackground: ' + settings.imageBackground);

      var exportImage = function () {
        /* Our wrapper binds to all important events to automatically
         * export the image data to the hidden input element, so whenever
         * the preview image is changed by file change, zoom change,
         * or offset change, the hidden input element has the new
         * cropped image data stored, and if the user submits the form,
         * the value can be serialized with no further ado.
         *
         * However, not all these events necessarily can be used
         * to export the cropped image, so we need to check a few
         * things to avoid errors in the CropIt plugin.
         */
//                console.log('awuxImageCropIt exportImage()');
        var imageSize = $(settings.this).cropit('imageSize');
//                console.log('awuxImageCropIt imageSize: ' + imageSize);
        if (!(imageSize.width === 0 || imageSize.height === 0)) {
          /* ok, there is at least an image loaded, but eventually
           * it has no offset yet, which will cause an error when
           * calling cropit('export'), so we first check if there
           * is already an offset defined, before we try export
           */
//                    console.log('awuxImageCropIt image is loaded');
          if (typeof $(settings.this).cropit('offset') !== 'undefined') {
            /* write value into the hidden input as data-uri,
             * so the value starts with something like this
             * "data:image/png;base64, "
             * */
//                        console.log('awuxImageCropIt call cropit("export")');
            $(settings.hiddenImageData).val($(settings.this).cropit('export'));
          }
        }
//                console.log('awuxImageCropIt previewSize: ' + $(settings.this).cropit('previewSize'));
//                console.log('awuxImageCropIt img.cropit-preview-image: ' + $(settings.this).find('img.cropit-preview-image'));
      };

      /* Initialize the jQuery CropIt widget: */
      $(settings.this).cropit({
        exportZoom: settings.exportZoom,
        width: settings.width,
        height: settings.height,
        imageBackground: settings.imageBackground,
        onFileChange: function () {
          exportImage();
        },
        onImageLoaded: function () {
          exportImage();
        },
        onZoomChange: function () {
          exportImage();
        },
        onOffsetChange: function (event) {
          exportImage();
        },
        onFileReaderError: function (event) {
          showAlerts(settings.awuxFileReaderError);
        },
        onImageError: function (error) {
          console.log(error);
          if (error.code === 0) {
            showAlerts(settings.awuxImage0Error);
          }
          if (error.code === 1) {
            showAlerts(settings.awuxImage1Error);
          }
        }
      });
      /* WORKAROUND immediately after initialization:
       * If settings.shape is 'circle' or 'rounded', we cannot just add
       * our convenient `img.img-circle` or `img.img-rounded` classes to
       * the preview image (it has not the desired effect).
       * Instead we need to add specific styles to the
       * `.cropit-preview-image-container` element.
       *
       * Only adding `border-radius` style would cause usability issues
       * with the plugin (v0.5.1), the developer suggests to add
       * `z-index`, too.
       *
       * For AWUX integration, the z-index should be 1100, so the
       * preview image is above the AWUX modal, and below auto-completion
       * (1200) and alerts (1300).
       *
       * .cropit-preview-image-container {
       *   border-radius: 50%;
       *   z-index: 1100;
       * }
       */
      if (settings.shape === 'circle') {
        $(settings.this).find('.cropit-preview-image-container').css("border-radius", "50%");
        $(settings.this).find('.cropit-preview-image-container').css("z-index", "1100");
      }
      else if (settings.shape === 'rounded') {
        $(settings.this).find('.cropit-preview-image-container').css("border-radius", "7%");
        $(settings.this).find('.cropit-preview-image-container').css("z-index", "1100");
      }
    });
  };
})(jQuery);
