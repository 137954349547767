/* global goog */
/*
* 
* @depends on
* ~ jQuery    1.9.1
* @author AniX (anh@hatzis.de)
* @version 0.1
*/

;(function ($) {
  $.fn.awuxChannel = function (options) {
    return this.each(function () {
      /* Manage channels with the Channel API of Google App Engine
       *
       * overwrite options for individual elements in the JS, e.g.:
       * $("a").awuxWindow({spinnerCSS: "fa fa-refresh fa-spin fa-2x"});
       */
      var settings = $.extend({
        'method': typeof $(this).data('awux-method') !== 'undefined' ? $(this).data('awux-method').toUpperCase() : 'POST', /* method to use for refresh channel token, default: 'POST' */
        'url': typeof $(this).data('awux-url') !== 'undefined' ? $(this).data('awux-url') : '/api/dca/v1/channels/refresh_token', /* method to use for refresh channel token, default: '/api/dca/v1/channels/refresh_token' */
        'contentType': 'application/json;charset=utf-8', /* the content-type in which we send the data to server */
        'dataType': 'json', /* the content-type of the response we expect from the server */
        'this': $(this)
      }, options);


      /* counter for channel errors */
      var channelErrors = 0;


      var connect = function (refreshedToken) {
        /* when plugin initially connects to channel after a page load,
         * the refreshedToken is not defined and the plugin will use
         * the element '#awux-channel' in the page.
         * If the token is invalid or expires, the onError() method
         * of this plugin will ask the server for a new token
         * and then call connect() with the refreshedToken
         */
        console.log("awuxChannel.connect");
        console.log("refreshedToken: " + refreshedToken);
        var token;
        if (typeof refreshedToken !== 'undefined') {
          token = refreshedToken;
        }
        else {
          token = settings.this.data('awux-channel-token');
        }
        console.log('token = ' + token);
        /* remove attribute value for security reasons */
        settings.this.data('awux-channel-token', '');
        console.log('awux-channel-token =' + settings.this.data('awux-channel-token'));

        /* create channel and bind awuxChannel plugin's methods to the socket */
        console.log('get channel');
        var channel = new goog.appengine.Channel(token);
        console.log('get socket');
        var socket = channel.open();
//          console.log('bind onopen event to ' + onOpened);
        socket.onopen = onOpened;
//          console.log('bind onmessage event to ' + onMessage);
        socket.onmessage = onMessage;
//          console.log('bind onerror event to ' + onError);
        socket.onerror = onError;
//          console.log('bind onclose event to ' + onClose);
        socket.onclose = onClose;
        console.log('awuxChannel.connect: connection established.');
      };


      var onOpened = function () {
        /* TODO */
        console.log("channelAPI:onOpened");
        $('#awux-brand').removeClass('awux-flash');
      };


      var onMessage = function (msg) {
        /* TODO */
        console.log("channelAPI:onMessage: " + $.parseJSON(msg.data));
        channelErrors = 0;
        $('#awux-brand').removeClass('awux-flash');
        var msgObj = jQuery.parseJSON(msg.data);
        console.log('msgObj: ' + msgObj);
        if ('items' in msgObj) {
          for (var i in msgObj.items) {
            var item = msgObj.items[i];
            console.log('item: ' + item);
            if ('key' in item) {
              console.log('msgObj.key:' + item.key);
              var $body = $('body');
              if (item.key in $body.data('awux-registry')) {
                console.log('item.key in registry');
                var regObj = $body.data('awux-registry')[item.key];
                /* FIXME: Version IDs are ignored because in some cases, versionIDs are not increased, see #852 and #876 */
//            			  if ('vid' in regObj && parseInt(item.vid, 10) > parseInt(regObj.vid, 10) ){
                console.log('Message will be processed.');
                var events = $(regObj.ctl).data('awux-events');
                var itemMsg = {
                  'id': msgObj.id,
                  'title': msgObj.title,
                  'body': msgObj.body,
                  'level': msgObj.level,
                  'key': item.key,
                  'vid': item.vid
                };
                window[events](itemMsg);
//            			  }
//            			  else {
//            				  console.log('Message has no version ID or version older than in registry. Message ignored.');
//            			  }
              }
              else {
                console.log('Message key not in registry. Message ignored.');
              }
            }
            else {
              console.log('Message has no key. Message ignored.');
            }
          }
        }
        else {
          console.log('Message has no items. Message ignored.');
        }
      };


      var onClose = function () {
        /* TODO */
        console.log("awuxChannel.onClose");
        $('#awux-brand').addClass('awux-flash');
      };


      var onError = function (errorMsg) {
        console.log("awuxChannel.onError: " + errorMsg.code + " " + errorMsg.description);
        if (channelErrors > 2) {
//    		  fadingAlert(errorMsg.code, 'Channel error: '+errorMsg.description, 'danger');
          $('#awux-brand').addClass('awux-flash');
        }
        else {
          try {
            channelErrors = channelErrors + 1;
          }
          catch (err) {
            console.log('Channel error:' + err.message);
//    			  fadingAlert(0, 'Channel error: '+err.message, 'danger');
          }
          try {
            /* Send the AJAX request and receive response */
            console.log('AJAX POST to server to refresh token');
            $.ajax({
              url: settings.url,
              type: settings.method,
              contentType: settings.contentType,
              dataType: settings.dataType,
              processData: false,
              statusCode: {
                /* Request is OK */
                200: function (data, statusText, jqXHR) {
                  console.log('AJAX SUCCESS: data = ' + data);
                  post200(data, statusText, jqXHR);
                }
              }
            })
              .fail(function (jqXHR, statusText, errorThrown) {
                console.log('AJAX FAILED');
                if (jqXHR.status >= 400 && jqXHR.status <= 499) {
                  post4xx(jqXHR, statusText, errorThrown);
                }
                else if (jqXHR.status >= 500 && jqXHR.status <= 599) {
                  post5xx(jqXHR, statusText, errorThrown);
                }
              });
          }
          catch (err) {
            console.log('error');
//    			  fadingAlert(0, err.message, 'danger');
          }
        }
      };

      /*
       *  The following methods are responsible to handle the received
       *  response, depending on the HTTP method of the request and the
       *  HTTP status code.
       */


      var post200 = function (data, statusText, jqXHR) {
        console.log('awuxChannel.post200');
        connect(data.alert[0].item[0].token);
        channelErrors = 0;
        $('#awux-brand').removeClass('awux-flash');
      };


      var post4xx = function (jqXHR, statusText, errorThrown) {
        console.log('awuxChannel.post4xx: ' + jqXHR.statusText);
//    	  fadingAlert( jqXHR.status, 'Channel Error: ' +jqXHR.statusText, 'warning');
        $('#awux-brand').addClass('awux-flash');
      };


      var post5xx = function (jqXHR, statusText, errorThrown) {
        console.log('awuxChannel.post5xx: ' + jqXHR.statusText);
//    	  fadingAlert( jqXHR.status, 'Channel Error: ' +jqXHR.statusText, 'warning');
        $('#awux-brand').addClass('awux-flash');
      };


      connect();


    });
  };
})(jQuery);

