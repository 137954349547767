/*
* 
* @depends on
* ~ jQuery    1.9.1
* @author AniX (anh@hatzis.de)
* @version 0.1
*/

;(function ($) {
  $.fn.awuxController = function (options) {
    return this.each(function () {
      /* Client can listen and unlisten to events of a certain type and eventually in certain scope
       *
       * overwrite options for individual elements in the JS, e.g.:
       * $("a").awuxWindow({spinnerCSS: "fa fa-refresh fa-spin fa-2x"});
       */
      var settings = $.extend({
        'url': {
          /* URLs to listen and unlisten */
          'listen': '/api/dca/v1/pushing/listen',
          'unlisten': '/api/dca/v1/pushing/unlisten'
        },
        'kind': typeof $(this).data('awux-kind') !== 'undefined' ? $(this).data('awux-kind') : null,
        'event': '', /* types of events to listen to */
        'key': typeof $(this).data('awux-key') !== 'undefined' ? $(this).data('awux-key') : null, /* urlsafe key of the entity to listen to */
        'vid': typeof $(this).data('awux-vid') !== 'undefined' ? $(this).data('awux-vid') : null, /* optional if key is given */
        'contentType': 'application/json;charset=utf-8', /* the content-type in which we send the data to server */
        'dataType': 'json', /* the content-type of the response we expect from the server */
        'this': $(this)
      }, options);

      /* if key, add it to body.data.awux.registry - a map of keys, vids and each with a list of this IDs
       * if vid, add the vid to the key
       * create a unique ID for the $(this) element - or maybe simpler if id="foo" is defined in HTML?
       * ERP_Workplace, 15
       * 	{
       * 		vid: null
       * 		controllers: []
       * 	},
       * 	{
       * 		vid: 57,
       * 		controllers: []
       * 	}
       *
       */

      var $body = $('body');
      if (settings.key in $body.data('awux-registry')) {
        /* TODO: how to handle this case? */
        console.log('awuxController: key in registry: ' + settings.key);
      }
      var regObj = {};
      if ('vid' in settings && settings.vid !== undefined) {
        regObj.vid = settings.vid;
      }
      regObj.ctl = this;
      $body.data('awux-registry')[settings.key] = regObj;
      console.log('awuxController: added key to registry: ' + settings.key);

      // var listen = function(kind, event, key, vid) {
      //  /* TODO */
      // };


      // var unlisten = function(kind, event, key, vid) {
      //  /* TODO */
      // };

      // var onPageLeave = function(kind, event, key, vid) {
      //  /* TODO */
      //  /* unlisten to all elements? or better haven listen-handler on server just ignore duplicate listen requests? */
      // };

    });
  };
})(jQuery);
