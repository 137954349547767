/*
 * AWUX AutoSelect: Select an entry from the datastore by entering search terms.
 *
 * AWUX AutoSelect is a wrapper plugin for jQueryUI autocomplete plugin and is applied to any input.auto-select element.
 * If the user enters a value, the plugin will create an AJAX GET request where the entered value is sent as HTTP query
 * parameter to a defined server URI and the server will provide a JSON response, including an array of matching result
 * items, {'results': []}, and additional meta data for navigating in the results. The plugin will use the JSON result
 * items, and eventually additional serialized JSON stored in the input's data-awux-payload attribute, to render the
 * result list.
 * The results then are displayed below the input element where the user can either select one of them or empty the
 * input. The hidden input element next to this input element will store the value of any selected item (where the
 * value is the URL-safe encoded datastore key of the item) or it is empty if the input was cleared by the user.
 *
 * @depends on
 * ~ jQuery    1.11.1
 * ~ jQueryUI  1.11.2
 * @author Maximilian Orth (maxx.orth@gmail.com)
 * @author AniX (anh@hatzis.de)
 * @version 1.0.0
 */

var showAlerts = require('./utils.js').showAlerts;
var render = require('./awuxView.jq.js').render;
var assignEvents = require('./events.js').assignEvents;
var parseIntDefault = require('./utils.js').parseIntDefault;
var parseBooleanDefault = require('./utils.js').parseBooleanDefault;

;(function ($) {
  /**
   * @function autoselect is a Wrapper Plugin for jqueryui autocomplete
   * @param {object} options - object for overwriting settings
   * @return {object} jQuery - selected jQuery element for chaining
   *
   * form:           The form that is used for making the AJAX GET request to the search API URI.
   *                 Normally, the autoselect input element (and its hidden input element that stores the actual
   *                 value) are inside a different form, that is used to (a) submit data to the server, or in case
   *                 this autoselect input element is used as an entity-filter of a search, to (b) create an HTTP query
   *                 parameter sent to a different search API URI. Do not confuse these two use-cases ;-)
   *
   *                 If the autoselect input element has no form attribute, an empty form will be created and
   *                 appended to the body element.
   *                 TODO: If filters support is added to the autoselect plugin, we will convert the JSON data,
   *                 that is provided in the filters attribute, into hidden input elements that are attached to the form.
   *
   * options attributes:
   * data-awux-url      : e.g. /registry/1/users, the URL where the AJAX GET request for the search is sent to
   * data-awux-source   : a string that points to the array of matching results in the JSON response of the server to
   *                      the search request; typically the server puts all matching items into "results" member
   * data-awux-delay    : an integer (miliseconds), see options of jQuery UI autocomplete; default: 500
   * data-awux-minLength: an integer (number of characters), see options of jQuery UI autocomplete; default: 2
   * data-awux-preview  : the ID of the element that shows a pretty preview of the selected item (optional)
   * data-awux-focusItem: "true" will automatically focus the very first item in results list, but also automatically
   *                      changing the input.auto-select value shown to the user, else set to "false"
   * data-awux-view     : the path to the template to use for client-side rendering of result items,
   *                      e.g. '/erp/working/ui/inlets/autosearch_results_workplace.html'
   *                      AWUX View plugin will retrieve the template and cache it. The template itself
   *                      contains `{{item}}` for injecting items from the `results` JSON object,
   *                      but also can use serialized JSON data stored in this input[data-awux-payload] attribute
   *
   */
  $.fn.autoselect = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes */
      var settings = $.extend({
        'url': $(this).attr('data-awux-url'),
        'source': $(this).attr('data-awux-source'),
        'delay': parseIntDefault($(this).attr('data-awux-delay'), 500),
        'minLength': parseIntDefault($(this).attr('data-awux-minLength'), 2),
        'focusItem': parseBooleanDefault($(this).attr('data-awux-focusItem'), false),
        'view': $(this).attr('data-awux-view') || '/dca/ui/inlets/autosearch_results.html',
        'searchFormID': $(this).attr('form'),
        'datatype': 'json',
        'valueInput': $(this).next('input[type="hidden"]'), /* the input[type="hidden"] that comes (immediately) next to the input.auto-select and stores the value */
        'preview': $(this).attr('data-awux-preview') ? $(this).attr('data-awux-preview') : false,
        'this': $(this)  /* the input element */
      }, options);

      /* if this input has form attribute, use this; else automatically create a form element, assign an
       * HTML ID to it, then connect it to this element
       */
      var searchForm;
      if (settings.searchFormID !== undefined) {
        searchForm = $('#' + settings.searchFormID);
      } else {
        var $body = $('body');
				$body.data('awux-autoselect-forms-counter', $body.data('awux-autoselect-forms-counter') + 1);
				settings.searchFormID = 'awux-autoselect-form-'+$body.data('awux-autoselect-forms-counter');
        searchForm = $('<form id="' + settings.searchFormID + '"></form>');
        $body.append(searchForm);
      }
      $(this).attr('form', settings.searchFormID);

      $.fn.destroy = function (options) {
        /* we need to manually remove the search form element because it is outside of this element */
        console.log('destroy(), options:' + options);
        $('#' + $(this).attr('form')).remove();
      };

      /**
       * @function getObjBysource for retrieving response object by source
       * @param {object} obj - object in which source is searched
       * @param {string} path - lookup path, e.g. 'payload.results'
       */
      var getObjBysource = function (obj, path) {
        var seqPath = path.split('.');
        if (seqPath[seqPath.length - 1] === "") {
          seqPath.pop();
        }
        /* jshint noempty: false */
        while (seqPath.length && (obj = obj[seqPath.shift()])) {
        }
        return obj;
      };

      var makeItem = function (ul, item) {
        /* item: this is the JSON object representing a match in the settings.source array (by default the
         * "results" array in the JSON response).
         * We want to provide this data to mustache rendering, but we also may have additional payload, so we
         * create an empty JSON object for all render data and store the item in the "item" member of the new
         * JSON object.
         */
        var _payload;
        if (typeof settings.this.attr('data-awux-payload') !== 'undefined') {
          _payload = settings.this.data('awux-payload');
        }
        else {
          _payload = {};
        }
        _payload.item = item;
        var res = $("<li>")
          .data('ui-autocomplete-item', item);
        render(settings.view, _payload, function (output) {
          /* TODO: show loading indicator? */
          res
            .append(output);
        });
        return res.appendTo(ul);
      };

      var makeMenu = function (ul, items) {
        var that = this;
        $.each(items, function (index, item) {
          that._renderItemData(ul, item);
          /* Use _renderItemData and not _renderItem with jQueryUI >= 1.9, see http://stackoverflow.com/a/13446828/1549523 */
        });
        $(ul).addClass('dropdown-menu');
        assignEvents($(ul));
      };

      /* standard jqueryui autocomplete call */
      var _ac = $(this).autocomplete({
        autoFocus: settings.focusItem,
        minLength: settings.minLength,
        delay: settings.delay,
        source: function (request, response) {
          $.ajax({
            url: settings.url,
            dataType: settings.datatype,
            data: searchForm.serialize(),
            success: function (data) {
              var _resultArray = getObjBysource(data, settings.source);
              response($.map(_resultArray, function (obj) {
                return obj;
              }));
            },
            error: function (data) {
              /* failed request; */
              showAlerts({
                'code': data.status,
                'title': data.statusText,
                'level': 'error'
              });
              return [];
            }
          });
        },
        focus: function (event, ui) {
          $(this).val(ui.item.title);
          return false;
        },
        /* when the auto-select input lose focus, check if its value is empty, if so set hidden value to empty (i.e. no object reference!) */
        change: function (event, ui) {
          if (!$(this).val()) {
            $(settings.valueInput).val('').change();
            /* we need to trigger change event in hidden input for awux instant forms */
          }
        },
        select: function (event, ui) {
          $(this).val(ui.item.title);
          var $hidden = $(settings.valueInput);
          $hidden.val(ui.item.key);
          $hidden.trigger('change');
          if (settings.preview !== false) {
            $("#" + settings.preview).html(ui.item.preview);
          }
          return false;
        },
        /* This here will prevent the message "X results are available, use up and down arrow keys to navigate" */
        messages: {
          noResults: '',
          results: function () {
          }
        }
      });
      /* set custom methods to render <ul> and <li> in search matches */
      _ac.data('ui-autocomplete')._renderItem = makeItem;
      _ac.data('ui-autocomplete')._renderMenu = makeMenu;
    });
  };
})(jQuery);

