/* assignEvents() is used on elements that will dynamically replace other elements in the document */
function assignEvents(target) {
  target.find('a.oembed').oembed();
  target.find('[data-awux-view]').awuxView();
  target.find('a.awux-line-add').awuxLineAdd();
  target.find('a.awux-line-remove').awuxLineRemove();
  target.find('button.awux-head-remove').awuxHeadRemove();
  target.find('a.help').popover({placement: 'auto bottom'});
  target.find('a.help-bottom').popover({placement: 'auto top'});
  target.find('.carousel').carousel('cycle');
  /* bootstrap carousel */
  target.find('ul.slider').cycle({
    /* jQuery Cycle plugin for small slide-shows */
    fx: 'fade', // here change effect to blindX, blindY, blindZ etc
    speed: 'slow',
    timeout: 2000
  });
  target.find('input.auto-search').autosearch();
  target.find('input.auto-select').autoselect();
  target.find('textarea.editor').editor();
//    target.find('input.dtpicker').dtpicker();
  target.find('a.awux-window').awuxWindow();
  target.find('button.awux-window').awuxWindow();
  target.find('form.awux-form-switch').awuxSwitch();
  target.find('form.awux-form-instant').awuxInstant();
  target.find('form.awux-form-filters').awuxFilter();
  target.find('.awux-form-fileuploader').awuxFormFileUploader();
  target.find('div.image-editor').awuxImageCropIt();
  target.find('input.awux-check').awuxCheck();
  target.find('input.awux-radio').awuxRadio();
  target.find('div.awux-ctl').awuxController();
  //$('#myModal').on('show.bs.modal', function () { 
  //    $
  //});

  /* assign non-AWUX plugins */
  $('button.erp-worksteps-before').erpRoutingWorkstepsBefore();
  $('button.erp-worksteps-after').erpRoutingWorkstepsAfter();
}

module.exports.assignEvents = assignEvents;
