/* Small jQuery plugin for parsing query string parameters into an object, from
 * https://gist.github.com/kares/956897
 *  posted by timvasil - https://gist.github.com/timvasil
 */
;(function ($) {
  var re = /([^&=]+)=?([^&]*)/g;
  var decode = function (str) {
    return decodeURIComponent(str.replace(/\+/g, ' '));
  };
  $.parseParams = function (query) {
    var params = {}, e;
    if (query) {
      if (query.substr(0, 1) === '?') {
        query = query.substr(1);
      }

      while ((e = re.exec(query))) {
        var k = decode(e[1]);
        var v = decode(e[2]);
        if (params[k] !== undefined) {
          if (!$.isArray(params[k])) {
            params[k] = [params[k]];
          }
          params[k].push(v);
        } else {
          params[k] = v;
        }
      }
    }
    return params;
  };
})(jQuery);

