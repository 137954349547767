;(function ($) {
  $.fn.awuxFormFileUploader = function (options) {
    return this.each(function () {
      /* creating defaults which are retrieved from elements data attributes
       * overwrite options for individual elements in the JS, e.g.:
       *  $("a").awuxWindow({spinnerCSS: "fa fa-refresh fa-spin fa-2x"});
       */
      var settings = $.extend({
        /* Uncomment the following to send cross-domain cookies: */
//		        xhrFields: {withCredentials: true},
        'url': $(this).attr('awux-url'),
        'redirect': window.location.href.replace(
          /\/[^\/]*$/,
          '/cors/result.html?%s'
        ),
        'contentType': 'application/json;charset=utf-8', /* the content-type in which we send the data to server */
        'dataType': 'json', /* the content-type of the response we expect from the server */
        'this': $(this)
      }, options);
      /* The element in the current page which has to be replaced (applies to inlet only) */

      /* Initialize the jQuery File Upload widget: */
      $(settings.this).fileupload({
        /* Uncomment the following to send cross-domain cookies: */
//		    	xhrFields: {withCredentials: true},
        /* THE URL IS SET IN FORM ACTION! */
        url: settings.url
      });

      /* Enable iframe cross-domain access via redirect option: */
      $(settings.this).fileupload(
        'option',
        'redirect',
        settings.redirect
      );

      /* Load existing files: */
      $(settings.this).addClass('fileupload-processing');

      /* Execute the AJAX request */
      $.ajax({
        /* Uncomment the following to send cross-domain cookies: */
//		        xhrFields: {withCredentials: true},
        url: $(settings.this).fileupload('option', 'url'),
        dataType: settings.dataType,
        context: $(settings.this)[0]
      }).always(function () {
        $(this).removeClass('fileupload-processing');
      }).done(function (result) {
        $(this).fileupload('option', 'done')
          .call(this, $.Event('done'), {result: result});
      });
    });
  };
})(jQuery);

